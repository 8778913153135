import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped, HeroShapedReversed } from 'components/organisms';
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles(() => ({
  map: {
    zIndex: 9
  },
  icon: {
    background: 'transparent',
    borderRadius: 0
  }
}));

const Contact = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title="Live chat"
              subtitle="Contact us via the website chat. This is the fastest way to reach us if you have any questions"
              titleProps={{
                variant: 'h4',
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textSecondary',
              }}
              ctaGroup={[
                <Box mt={3}>
                  <Button variant="outlined" color="secondary" size="large" href="/productionbook">
                    Start chatting
                  </Button>
                </Box>
              ]}
              data-aos="fade-up"
              align="left"
            />
          </div>
        }
        rightSide={
          <StaticImage
            className={classes.logoImage}
            src='../../../../../assets/images/qeerio/chat.jpg'
            alt="Chat image"
            placedholder="blurred"
            loading="lazy"
          />
        }
      />
      <HeroShapedReversed
        leftSide={
          <div>
            <SectionHeader
              title="Email"
              subtitle="You have a more specific request? An email is always the easiest!"
              titleProps={{
                variant: 'h4',
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textSecondary',
              }}
              ctaGroup={[
                <Box mt={3}>
                  <Button variant="outlined" color="secondary" size="large" href="mailto:info@qeerio.com">
                    Send an email
                  </Button>
                </Box>
              ]}
              data-aos="fade-up"
              align="left"
            />
          </div>
        }
        rightSide={
          <StaticImage
            className={classes.logoImage}
            src='../../../../../assets/images/qeerio/email.jpg'
            alt="Email image"
            placedholder="blurred"
            loading="lazy"
          />
        }
      />
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title="Instagram"
              subtitle="Any questions? We are also very fast to answer on instagram"
              titleProps={{
                variant: 'h4',
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textSecondary',
              }}
              ctaGroup={[
                <Box mt={3}>
                  <Button variant="outlined" color="secondary" size="large" href="https://www.instagram.com/qeerio.app">
                    Send direct message
                  </Button>
                </Box>
              ]}
              data-aos="fade-up"
              align="left"
            />
          </div>
        }
        rightSide={
          <StaticImage
            className={classes.logoImage}
            src='../../../../../assets/images/qeerio/instagram.jpg'
            alt="Instagram image"
            placedholder="blurred"
            loading="lazy"
          />
        }
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array
};

export default Contact;
