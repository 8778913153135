import React from 'react';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { Contact, Form, Hero, Newsletter } from './components';

import { mapData } from './data';

const Support = () => (
  <div>
    <Hero />
    <Contact />
    <Divider />
  </div>
);

export default Support;
